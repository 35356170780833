.blc_card {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 0.8);
  color: #000 !important;
}

.blc_button {
  font-size: 0.75rem !important;
}

.blc_nav_active {
  border-bottom: 3px solid #0D0C0A;
  background: transparent !important;
  color: #0D0C0A !important;
  border-radius: 0px !important;
}

.blc_event_footer {
  background: #80808014;
}

.blc_event_blc {

  background: transparent;

  padding-left: 4px;

  padding-right: 4px;

  padding-top: 8px;

  padding-bottom: 8px;

}

.fa-1x {
  font-size: 1rem !important;
}

.fa-1-5x {
  font-size: 1.5rem !important;
}

.bl-font-larger {
  font-size: larger !important;
}

.bl-text-medium {
  font-size: medium !important;
}

.footer {
  position: absolute;
  bottom: 25px;
  width: 100%;
  line-height: 1.2;
}

/*!
 * Custom Styles
 */
.bl_H1_Color {
  color: #070B31 !important;
}

.bl_H2_Color {
  color: #333333;
}

.bl_link_Color {
  color: #47ABE1;
}

.bl-menu-btn-primary {
  background-color: #47abe1 !important;
  border-color: #47abe1 !important;
}

.bl_no_padding_right {
  padding-right: 0 !important;
}

/* --login page styles-- */
.bl-login-bg {
  background: url('../assets/img/bglogin.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  /* backdrop-filter: blur(5px); */
}

.bl-login-img-height-20 {
  height: 20rem;
}

.bl-login-card {
  background-color: #0405068a !important;
}

.bl-color-crimson {
  color: crimson;
}

.bl-mt-7rem {
  margin-top: 7rem !important;
}

.bl_login_formElements {
  border-radius: 0.3rem !important;
}

.bl_disabled {
  opacity: 0.4;
}

.bl_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blc_card_head {
  color: #565555;
  font-weight: bold;
}

.bl-font-bold {
  font-weight: bold;
}


/* --collapsing styles and logic-- */
@media (min-width: 768px) {
  .blContentMargin {
    margin-top: 55px;
    margin-left: 224px;
  }

  .blCollapsedContentMargin {
    margin-left: 64px !important;
  }

  .blHeaderMargin {
    left: 219px;
  }

  .blCollapsedHeaderMargin {
    left: 64px !important;
  }

}

@media (max-width: 768px) {
  .blContentMargin {
    margin-top: 55px;
    margin-left: 0px;
  }

  .blHeaderMargin {
    left: 100px;
  }

  .blCollapsedHeaderMargin {
    left: 0px !important;
  }

}

/*  ---- thumbnail checkboxes-------- */
.bl_thumb_container {
  position: relative;
}

.bl_thumb_checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  cursor: pointer;
}

.bl_thumb_height {
  height: 50px !important;
  opacity: 0.6;
}

.bl_thumb_height:hover {
  filter: invert(1);
  cursor: pointer;
}

.bl_thumb_active {
  filter: invert(1);
}

/*  ---- nav icons-------- */
.bl_nav_icon {
  height: 15px !important;
  filter: contrast(0.3);
  margin-right: 5px;
}

.bl_nav_icon_active {
  filter: invert(1);
}

/*  ---- timeline styles-------- */
ul.timeline {
  list-style-type: none;
  position: relative;
  font-size: 12px;
  margin-left: -18px;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline>li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #E94359;
  left: 22px;
  width: 15px;
  height: 15px;
  z-index: 400;
}

.timelineMainText {
  font-weight: 500;
}

/*  ---- homepage card styles-------- */
.blHomePageCard {
  border: 0px !important;
}

.blHomePageCardHeader {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 3px solid #47ABE1;
}

.blHomePageCardBody {
  background: #f9f9f9;
  padding-left: 0px;
  padding-right: 0px;
  color: #070B31;
}

/* mark favourite compoenet */

/* .star-rating {
  border:solid 1px #ccc;
  display:flex;
  flex-direction: row-reverse;
  font-size:1.5em;
  justify-content:space-around;
  padding:0 .2em;
  text-align:center;
  width:5em;
} */

.star-rating {
  font-size: 1.7em;
  margin-top: -5px;
}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
}

.star-rating :checked~label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover~label {
  color: #fc0;
}

/* ---------------- sliding panel ---------------------------------- */

.modal-dialog.modal-dialog-slideout {
  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
  border: 0;
}

.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}

.modal-dialog-slideout .modal-header h5 {
  float: left;
}

/* --------------------------- guest page ------------------------------------ */


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.blMarkImage {
  background-color: transparent;
  border-radius: 0;
  border: 0px;
}

.bl-social-links a {
  font-size: 1rem;
  display: inline-block;
  color: var(--black-40, rgba(28, 28, 28, 0.40)) !important;
  line-height: 0;
  margin-right: 1rem;
  transition: 0.3s;
  cursor: pointer;
}

.bl-accordion-icon {
  float: right;
  border: 1px solid;
  padding: 0 0.25rem;
  border-radius: 2px;
  cursor: pointer;
}

.bl-accordion-icon .collapsed {
  transform: rotate(270deg);
}

.bl-company-profile img {
  height: 3.625rem;
}

.bl-individual-section div {
  display: flex;
  gap: 1rem;
  place-items: center;
  padding: 0.8rem;
}

.bl-user-permission img {
  height: 2rem;
  width: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.bl-activity-item {
  gap: 0.5rem;
  position: relative;
}

.bl-activity-badge {
  height: 1rem;
  width: 1rem;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 0 0 5px white;
  background: center/contain no-repeat url('https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png');
}

.bl-activity-item::before {
  content: "";
  height: 90%;
  width: 1px;
  background-color: #1C1C1C1A;
  position: absolute;
  transform: translateX(0.4rem);
  bottom: 0;
}

.bl-activity-item:last-child::before {
  height: 0;
}

.bl-filter-clear {
  color: #E94359 !important;
}

.btn-primary-login {
  background-color: #8b8b8ca8;
  border-color: #8b8b8ca8;
  color: #fff;
}

.btn-primary-login:hover {
  background: #0e0f10;
}

.bl-overview-img {
  height: 6.5rem;
}

.bl-report-thumbnail {
  height: 2rem;
  flex-shrink: 0;
}

.bl-chip {
  border-radius: 3.75rem;
  display: inline-flex;
  padding: 0.3125rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  color: #232323;
  margin-right: 0.25rem;
  border: 1px solid #232323;
  font-weight: 500;
}

.bl-filter-chip,
.bl-chip-btn {
  display: flex;
  padding: 0.4rem 1.0625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F1F1F1;
  color: #232323;
  margin-right: 0.25rem;
  font-weight: 600 !important;
}

.bl-filter-chip.active,
.bl-chip-btn.active {
  background: #232323;
  color: #FFFFFF;
}

.bl-activity {
  display: flex;
  padding: var(--spacing-4, 0.25rem);
  align-items: flex-start;
  gap: var(--spacing-8, 0.5rem);
  align-self: stretch;
  font-size: 12px;
}

.bl-user-activity-icon {
  padding: 0.25rem;
  background: #b7d9f8;
  height: 1.2rem;
  width: 1.2rem;
}

.bl-w-17rem {
  width: 17rem !important;
}

.bl-ml-17rem {
  margin-left: 17rem !important;
}

.bl-grid-img {
  height: 9.375rem;
  object-fit: cover;
}

.bl-catalog-category-view table tbody tr:last-child td {
  border-bottom: none;
}

.bl-report-count {
  /* float: right; */
  background: #fff;
  color: #232323;
  padding: 0 0.2rem;
  border-radius: 4px;
  font-size: 10px !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

figure pre {
  text-wrap: inherit;
}

table.bl-company-overview-risk {
  border-collapse: inherit;
  border-spacing: 15px;
}

table.bl-company-overview-risk tr td {
  border: 1px solid #e3e6f0;
  border-radius: 6px;
}


/* SWITCH BUTTON */

.bl-switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 14px;
}

.bl-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bl-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.bl-slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.bl-slider {
  background-color: #e74a3b;
}

input:focus+.bl-slider {
  box-shadow: 0 0 1px #e74a3b;
}

input:checked+.bl-slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.bl-slider.round {
  border-radius: 34px;
}

.bl-slider.round:before {
  border-radius: 50%;
}

.bl-card-deck {
  margin: 0;
  gap: 1%;
}

.bl-ai-card-deck {
  margin: 0;
  gap: 1%;
}

.bl-ai-card-deck .card {
  min-width: 49%;
  max-width: 50%;
  margin: 0;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .bl-card-deck .card {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .bl-card-deck .card {
    min-width: 49%;
    max-width: 50%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .bl-app-card {
    min-width: 23%;
    max-width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .bl-recommended {
    max-width: 31%;
    min-width: 31%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .bl-ai-model-card-deck {
    max-width: 48%;
    min-width: 48%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }
}

@media (min-width: 992px) {
  .bl-card-deck .card {
    min-width: 32%;
    max-width: 32%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .bl-app-card {
    min-width: 15%;
    max-width: 15%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .bl-recommended {
    max-width: 23%;
    min-width: 23%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .bl-ai-model-card-deck {
    max-width: 32%;
    min-width: 32%;
    margin-left: 0.6% !important;
    margin-right: 0.6% !important;
  }
}

@media (min-width: 1200px) {
  .bl-card-deck .card {
    min-width: 24%;
    max-width: 24%;
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

.bl-rounded-info {
  background: #67b7dc;
  border-radius: 50%;
  color: #fff;
  padding: 5px;
}

.bl-w-15per {
  width: 15%;
}

.bl-w-5rem {
  width: 5rem;
}

.bl-desc-truncate-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bl-desc-truncate-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.bl-h-2 {
  height: 2.5rem;
}

.bl-h-1-4rem {
  height: 1.4rem;
}

.bl-h-2rem {
  height: 2rem;
}

.bl-minh-10rem {
  min-height: 10rem;
}

.bl-minh-15rem {
  min-height: 15rem;
}

.d-grid {
  display: grid;
}

.w-40 {
  width: 40% !important;
}

.bl-text-due-deligence {
  color: #0B84A5;
}

.bl-text-negotiation {
  color: #B47E04;
}

.bl-text-closed {
  color: #6F4E7C;
}

.bl-modal-context {
  background: #ffffffbf;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.bl-min-vh-95 {
  min-height: 95vh;
}

.bl-min-vh-94 {
  height: 88vh;
}

.bl-vh-93 {
  height: 93.6vh;
}

.bl-modal-action {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: auto;
}

.bl-search-footer {
  position: sticky;
  bottom: 20px;
  left: 10px;
  right: 10px;
  border-radius: 10px;
  z-index: 2;
}

.bl-bottom-4rem {
  bottom: 4rem !important;
}

.bl-bottom-70px {
  bottom: 70px !important;
}

.bl-rotate-175 {
  transform: rotate(175deg);
}

.bl-modal-action {
  z-index: 9999;
}

.bl-modal-content {
  position: absolute;
  width: 100%;
  bottom: 3rem;
}

.active-menu img,
.dropdown-item img {
  width: 1.1rem;
}

.w-1-1rem {
  width: 1.1rem;
}

.bl-h-1-25rem {
  height: 2rem;
}

.bl-border-bottom-grad {
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #d9d9d900, #d9d9d9, #d9d9d900) 1;
}

.bl-border-right-grad {
  border-right: 1px solid;
  border-image: linear-gradient(180deg, #d9d9d900, #d9d9d9) 1;
}

.bl-border-right-grad-2 {
  border-right: 1px solid;
  border-image: linear-gradient(180deg, #d9d9d9, #d9d9d900) 1;
}

.bl-w-3rem {
  width: 3rem;
}

.bl-w-95 {
  width: 95% !important;
}

.bl-ai-sidebar {
  width: 19rem !important;
  max-height: 95.3vh;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
  padding-bottom: 5rem;
}

.bl-ai-sidebar .nav-item.active {
  background: #80808017;
  border-left: 3px solid gray;
}

.bl-min-vh-98 {
  min-height: 98vh;
}

.bl-vh-98 {
  height: 98vh;
}

.bl-vh-95 {
  height: 95vh;
}

.bl-card-visibility {
  transform: translateZ(0)
}

.bl-minimize-modal {
  width: 0 !important;
  height: 0 !important;
}

.bl-minimize-content {
  min-height: 2rem !important;
  position: fixed;
  width: 15rem;
  bottom: 0;
  right: 20px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.bl-mw-30rem {
  max-width: 30rem;
}

.bl-mt-10rem {
  margin-top: 10rem;
}

.bl-mb-20rem {
  margin-bottom: 20rem;
}

.bl-mb-15rem {
  margin-bottom: 15rem;
}

.bl-mb-4rem {
  margin-bottom: 4rem !important;
}

.bl-mt-20 {
  margin-top: 20px;
}

.bl-pt-20 {
  padding-top: 20px;
}

.bl-assistant-sec {
  border-bottom: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  border-top: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  background: rgba(233, 67, 89, 0.03);
}

.bl-ai-insight {
  border-radius: 0.25rem;
  border: 1px solid #FAD207;
  background: rgba(250, 210, 7, 0.08);
}

.bl-body-hover:hover {
  background: #fafafa;
}

.bl-source-stage-body {
  height: 100%;
  display: grid;
  grid-template-rows: 40% 60%;
}

.bl-company-source-stage {
  height: 0.4375rem;
  align-self: center;
  min-width: 20%;
}

.bl-prospects {
  background-color: #F6C85F;
  border-color: #F6C85F;
}

.bl-due-diligence {
  background-color: #69DEFE;
  border-color: #69DEFE;
}

.bl-negotiation {
  background-color: #0B84A5;
  border-color: #0B84A5;
}

.bl-closed {
  background-color: #DE89FF;
  border-color: #DE89FF;
}

.bl-default-status {
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}

.bl-h-10px {
  height: 0.7rem !important;
}

.tooltip-inner {
  font-size: x-small !important;
}

.bl-text-underline-hover:hover {
  text-decoration: underline;
  color: blue;
}

.bl-h-3rem {
  height: 3rem;
}

.bl-app-pinned-pos {
  position: absolute;
  top: 10px;
  right: 10px;
}

.left-100 {
  left: 100% !important;
}

.bl-recommended-app-icon {
  width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
}

.bl-w-9rem {
  width: 9rem;
}

.bl-rule-box1 {
  border-bottom: 3px solid #FF403B;
}

.bl-rule-box2 {
  border-bottom: 3px solid #FFBA39;
}

.bl-rule-box3 {
  border-bottom: 3px solid #8AD129;
}

.bl-task-category {
  height: 2rem;
  width: 2rem;
}

.tooltip {
  z-index: 9 !important;
}

.bl-h-4rem {
  height: 4rem;
}

.bl-w-fill-available {
  width: -webkit-fill-available;
}

.bl-limited-text {
  white-space: nowrap;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bl-like-dislike-icon {
  padding: 2px 4px;
  background: #80808091;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.bl-bg-lightpink {
  background: #fdebee !important;
}

.bl-selected-source {
  border-radius: 3px;
  backdrop-filter: blur(8px);
  border: 0.5px solid #80808029;
}

.bl-truncate-line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.bl-informative-cards {
  background: #DDE7F1;
}

.bl-h-75rem {
  height: 0.75rem !important;
}

.bl-empty-icon {
  background: url(./../assets/img/empty-widget.png), lightgray 50% / cover no-repeat;
}

.card.shadow-sm .card-header {
  background-color: #00000008 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.bl-profile-heighlights p,
.bl-profile-heighlights a {
  font-size: 13px;
}

.bl-finance-graph thead th {
  white-space: nowrap;
}

.bl-articles-card .card {
  max-width: 23.5% !important;
  min-width: 23.5% !important;
  margin-bottom: 1rem;
}

.bl-w-50 .card {
  max-width: 50% !important;
  min-width: 50% !important;
}

.bl-configure-btn::after {
  display: inline-block !important;
}

.bl-source-badge {
  color: rgba(33, 33, 33, 0.60);
  font-size: 0.6375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  background-color: transparent;
  display: inline-flex;
  padding: 0.0625rem 0.4375rem;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.5rem;
  border: 0.5px solid rgba(0, 0, 0, 0.20);
  height: 1rem;
  align-self: center;
}

.bl-h-9rem {
  height: 9rem;
}

.bl-source-overview-list:hover .bl-hover-show {
  display: block !important;
}

.bl-hover-show {
  display: none;
  top: -2rem;
  right: 0;
  width: 'auto';
  cursor: pointer;
}

.bl-fixed-right-panel {
  position: fixed;
  right: 0;
  width: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 95vh;
}

.bl-menu-accordion {
  padding: 0.5rem 0.2rem 0rem 0.8rem !important;
  color: #ffffff8c !important;
}

.bl-bg-black {
  background: #000 !important;
}

.bl-menu-accordion .nav-item.selected button {
  background: #434141 !important;
}

.nav-item.selected .bl-menu-accordion .nav-item button {
  color: #ffffff8c !important;
}

.nav-item.selected .bl-menu-accordion .nav-item.selected button {
  color: #ffffff !important;
}

.bl-pr-point3 {
  padding-right: 0.3rem !important;
}

.bl-ai-logout {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  position: fixed;
  bottom: 0;
  padding-bottom: 2rem;
  width: 19rem !important;
  margin-left: 12px;
  background-color: #fff;
}

.bl-border-color {
  border-color: #e74a3a7d;
}

.bl-grayscale {
  filter: grayscale(0.8);
}

.bl-w-22rem {
  width: 22rem !important;
}

.bl-w-1-7rem {
  width: 1.7rem;
}

.bl-inner-html-content h1,
h2,
h3 {
  font-size: 0.9rem;
}

/* .bl-inner-html-content h4, h5 {
  font-size: 0.8rem;
} */
.bl-inner-html-content h6,
.accordion-item h6 {
  font-size: 0.75rem;
}

.bl-inner-html-content iframe {
  border: none;
  min-height: 20vh;
}

.bl-inner-html-content iframe html body {
  overflow: hidden;
}

/* .bl-inner-html-content table {
  width: 100%;
  margin-bottom: 1rem;
  color: #070B31;
  border: 1px solid rgb(217 217 217) !important;
} */

.bl-profile-circle {
  border-radius: 50% !important;
  padding: 10px;
  color: #253a6b;
  font-weight: 500;
  font-size: 10px;
  border: 1px solid;
}

.bl-mh-2rem {
  max-height: 2rem;
}

.bl-static-modal {
  background: #80808075;
}

.bl-z-index-1 {
  z-index: 1 !important;
}

.bl-z-index-n1 {
  z-index: -1;
}

.BLC_chat_container {
  background: url(./../assets/img/AI_chatBG.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.bl-analytic-overview-main {
  width: calc(100% - 15.5rem);
}

.bl-analytic-overview-insights-1 {
  background: url(./../assets/icons/adh_1.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 6rem;
}

.bl-analytic-overview-insights-2 {
  background: url(./../assets/icons/adh_2.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 4rem;
}

.bl-analytic-overview-insights-3 {
  background: url(./../assets/icons/adh_3.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 4rem;
}

.unauthorize-page {
  background-image: url('./../assets/img/unauthorize-access.jpg');
  background-position: top;
}

.unauthorize-card {
  border: 1px solid #80808078;
  border-radius: 10px;
}

.modal-content-wrapper {
  width: calc(100% - 21rem) !important;
}

.bl-helper-content img {
  width: 32px;
  height: 32px;
}

.kairos_helpscreen_footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 0rem;
}

/* Since positioning the image, we need to help out the caption */
.kai_carousel-caption {
  padding-top: 4%;
  text-align: center !important;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  /* height: 80vh; */
  background-color: transparent;
}

.carousel-item>img {
  /* position: absolute; */
  top: 0;
  left: 0;
  max-width: 100%;
}

.bl-overflow-unset {
  overflow: unset !important;
}

.bl-mb-5rem {
  margin-bottom: 5rem !important;
}

#logoutModal {
  z-index: 99999;
  background: #8080808c;
}

.bl-w-80 {
  width: 80% !important;
}

.bl-left-auto {
  left: auto !important;
}

.bl-answer-length {
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.bl-modal-body-card-length {
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.bl-iframe-height {
  height: calc(100vh - 160px);
  border: none;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none !important;
}

.bl-positive-sentiment {
  color: #4CAF50;
}

.bl-negative-sentiment {
  color: #F44336;
}

.bl-neutral-sentiment {
  color: #FFC107;
}

.bl-ai-sidebar a[href]:hover {
  text-decoration: underline;
}

.bl-insight-btn {
  color: #000000 !important;
  border: 1px solid #000;
}

.bl-insight-btn:hover {
  background-color: #c6bfa6;
}

.bl-left-30px {
  left: 30px !important;
}

.bl-selected-source-sec {
  width: calc(100% - 1.5rem);
}

.bl-w-1rem {
  width: 1rem;
}

.bl-kairos-menu {
  position: fixed;
  top: 0;
  margin: 0;
  z-index: 9999;
  border-radius: 0;
}

.bl-kairos-main-content {
  margin-left: 20rem;
}

.bl-kairos-top-header {
  left: 23rem !important;
}

.bl-text-unset {
  color: unset !important;
}

.bl-kairos-menu-btn {
  background-color: #d3d3d345 !important;
  color: #fff;
}

.bl-kairos-content-height {
  height: calc(100vh - 3.5rem) !important;
}

.h-99 {
  height: calc(100vh - 155px);
}

.bl-component-header {
  top: 50px;
  border: 0;
  padding-bottom: 0;
  box-shadow: 0px 8px 8.9px 0px rgba(0, 0, 0, 0.05);
  background-color: #fafafa !important;
}

.bl-component-header .nav-item.active,
.bl-kairos-chat-sec .nav-item.active {
  border-bottom: 2px solid #000000;
  color: #000000 !important;
  font-weight: 500;
}

.bl-component-header .nav-item .nav-link,
.bl-kairos-chat-sec .nav-item .nav-link {
  color: #000000 !important;
}

.bl-user-list {
  max-height: 15rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bl-user-list .active {
  background: #070b31 !important;
  color: white;
}

.bl-line-height {
  line-height: 1.4 !important;
}

.bl-bottom-15 {
  bottom: 1.4rem !important;
}

.bl-btn-grp-fixed {
  position: fixed;
  right: 15px;
  top: 70px;
  z-index: 1;
}


.bl-brief-bg,
.bl-brief-bg:focus,
.bl-brief-bg:focus-visible {
  background-color: #ffffff1c;
  box-shadow: none;
}

.bl-brief-border {
  border-radius: 0 0 3px 3px;
}

.bl-brief-input,
.bl-brief-input:focus,
.bl-brief-input:focus-visible {
  background-color: #ECECEC;
  border-radius: 10px;
}

.bl-brief-transcript-bg {
  background-color: #EFF2F7;
}

.bl-brief-body-height {
  min-height: calc(100vh - 28rem);
}

.bl-left-23rem {
  left: 23rem !important;
}

.bl-pt-12rem {
  margin-top: 12rem;
}

.textAreaContainer {
  position: relative;
}

.textAreaContainer textarea {
  display: block;
  resize: vertical;
}

.textAreaContainer .label {
  position: absolute;
  left: 0;
  top: 0;
  right: 15px;
  padding: 15px 15px 0 15px;
  background-color: #ECECEC;
  border-radius: 10px;
}

.modal-xlg {
  max-width: 75% !important;
}

.bl-z-index-1031 {
  z-index: 1031 !important;
}

.bl-row-border-none td {
  border: none !important;
}

.bl-inner-html-content table {
  width: 100%;
}

.bl-inner-html-content h1,
h2,
h3,
h4,
h5 {
  margin-top: 0.5rem !important;
}

.bl-embed-file {
  height: calc(100vh - 85px);
}

.hidden-table {
  display: none !important;
}

.bl-innerhtml-iframe {
  border: none;
  /* height: 100vh; */
  /* overflow: auto; */
}

.bl-innerhtml-iframe body ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.bl-inner-html-content .container {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

/* .bl-innerhtml-iframe html body{
  max-height: 700px;
} */

/* dealbook styles */
.bl-db-summaryContainerMargin {
  margin-right: 14rem;
}

.bl-template-bg {
  background-color: #EEECE2;
}

.bl-template-table-th {
  border-right-color: #EEECE2;
  background-color: #EEECE2;
}

.bl-db-kpi-fontsize {
  font-size: 1.3rem;
}

.page-break {
  page-break-before: always;
}

.bl-h-2px {
  height: 2px;
}

/* speaker brief styles */
.bl-sb-tiles-bg-process {
  background-color: #ffddb74f;
  border-color: #c4c7c591;
}

.bl-sb-tiles-bg-published {
  background-color: #35b9cc0f;
  border-color: #c4c7c591;
}

.bl-sb-tiles_footer {
  font-size: smaller;
}

.bl-sb-datatableTabs {
  font-size: 80% !important;
}

/* dealbook styles */
.bl-db-actionBtnMarginRight {
  right: 253px;
  top: 93px;
  background-color: #fff;
}

.bl-mw-2rem {
  min-width: 2rem !important;
}

.bl-mw-28px {
  min-width: 28px !important;
}

.p-progressbar {
  height: 10px !important;
  font-size: 10px !important;
}

.bl-progress-cell {
  width: 55%;
  position: absolute;
  inset: 0;
  margin: auto;
  height: fit-content;
  right: 10%;
}

.bl-db-pageBadge {
  background-color: #fdb02a36;
  color: #885701;
  cursor: pointer;
}

.bl-db-pageBadge:hover {
  background-color: #fdb029;
  color: #fff;
}

.bl-db-fileupload-border {
  border: 1px dashed #9393934c;
}

.bl-db-datable-icon {
  color: #c1c1c1;
}

.bl-db-datable-icon:hover {
  color: #000;
  cursor: pointer;
}

/* markdown display only style overwrite */
.wmde-markdown {
  -webkit-text-size-adjust: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  word-wrap: break-word !important;
  color: #212121 !important;
  background-color: transparent !important;
}

.wmde-markdown h1 {
  font-size: 1.2rem !important;
  text-transform: capitalize !important;
  border: 0px !important;
}

.wmde-markdown h2 {
  font-size: 1.2rem !important;
  text-transform: capitalize !important;
  border: 0px !important;
}

.wmde-markdown h3 {
  font-size: 1rem !important;
  text-transform: capitalize !important;
  border: 0px !important;
}

.wmde-markdown hr {
  border: 0;
  border-bottom: 1px solid #e3e6f0 !important;
  height: 0px !important;
}

.wmde-markdown table {
  display: table !important;
  width: 100% !important;
}

.wmde-markdown table tr {
  background-color: transparent !important;
  border: 1px solid #e3e6f0 !important;
}

.wmde-markdown table th {
  padding: 6px 13px;
  /* border-bottom: 2px solid #e3e6f0 !important; */
  border: solid #e3e6f0 !important;
  border-width: 0 1px 2px 0 !important;
  font-size: 0.775rem !important;
}

.wmde-markdown table td {
  padding: 6px 13px;
  border: 1px solid #e3e6f0 !important;
  font-size: 0.75rem !important;
}

.wmde-markdown ul {
  display: block !important;
  list-style-type: disc !important;
  margin-block-start: 0.5rem !important;
  margin-block-end: 0.5rem !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  /* padding-inline-start: 40px!important; */
  unicode-bidi: isolate !important;
}

.wmde-markdown ul li {
  display: list-item !important;
  text-align: -webkit-match-parent !important;
}

.tiptap h1 {
  font-size: 1.2rem !important;
}

.tiptap h3 {
  font-size: 1rem !important;
}

.tiptap h4 {
  font-size: 1rem !important;
}

.bl-top-0 {
  top: 0 !important;
}

.text-center .p-column-header-content {
  text-align: center !important;
  justify-content: center;
}

.wmde-markdown table p {
  margin: 0 !important;
}

.bl-btn-doughnut {
  height: 60px !important;
  width: 75px !important;
}

.bl-db-sliderContainer {
  min-width: 15rem !important;
}

.slider-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.slider {
  position: relative;
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

.slider-track {
  position: absolute;
  height: 100%;
  background: #ccc;
  border-radius: 3px;
}

.slider-range {
  position: absolute;
  height: 100%;
  background: #3b82f6;
  border-radius: 3px;
}

.thumb {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.thumb-left {
  z-index: 3;
}

.thumb-right {
  z-index: 3;
}

.bl-range-slider {
  position: absolute;
  width: 100%;
  height: 0;
  pointer-events: none;
  opacity: 0;
}

.bl-range-slider::-webkit-slider-thumb {
  pointer-events: all;
}

.bl-range-slider::-moz-range-thumb {
  pointer-events: all;
}

.bl-profile-dropdown {
  position: absolute;
  right: 7px;
  background: white;
  top: 4.3rem;
  border-radius: 5px;
  overflow: hidden;
  isolation: isolate;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 22rem;
}

.bl-min-w-15rem {
  min-width: 15rem;
}

.form-control {
  color: #000000 !important;
}

.bl-testing-progress {
  width: 40% !important;
  inset: auto !important;
  top: 14px !important;
}

.bl-inset-auto {
  inset: auto !important;
}

.bl-right-unset {
  right: unset !important;
}

.btn-circle-sm {
  height: 1rem !important;
  width: 1rem !important;
  font-size: 0.5rem !important;
}

.kairos-modal-left-panel {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kairos-modal-right-panel {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kairos-modal-content {
  flex-grow: 1;
}

.kairos-modal-panel-height {
  height: calc(100vh - 75px);
}

.bl-border-radius-round {
  border-radius: 100% !important;
}

.bl-h-38px {
  height: 2.4rem;
}

.bl-helpText-header-height {
  height: 3.3rem;
}

.bl-helpText-card-height {
  height: 17rem !important;
}

.bl-helpText-questionTextFont {
  font-size: 70% !important;
}

.bl-helpText-borderColor {
  border: 1px solid #d9d9d98c !important;
}

.bl-helpText-containerAnchor {
  position: relative;
}

.bl-helpText-containerAnchor section {
  position: absolute;
  width: 100%;
}

.bl-helpText-containerMargin {
  margin-bottom: 25rem !important;
}

.bl-justify-items-center {
  justify-items: anchor-center;
}

.bl-top-25 {
  top: 25% !important;
}

.bl-hero-background {
  background-color: #3c63fd08;
}

.bl-hero-icon-opacity30 {
  opacity: 0.3;
}

.bl-hero-icon-opacity10 {
  margin-top: 10%;
  opacity: 0.1;
}

.bl-noAgent-Img {
  height: 360px;
  background: url('../assets/img/noAgentFound.jpg') no-repeat center center;
  margin-top: 10%;
}

.bl-line-break {
  border-color: #cecfd1;
}

.bl-delete-button-container {
  width: 100px;
  display: grid;
  place-content: center;
}
 
.css-1dyz3mf{
  height: auto;
  min-height: 25px;
}

.css-1wy0on6 {
  height: 25px;
}

.bl-search-box-lg {
  white-space: nowrap;
  width: 15rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bl-w-h-4rem {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}