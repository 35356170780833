.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.search-bar {
  border-radius: var(--radius-8, 0.5rem);
  background: var(--black-5, rgba(28, 28, 28, 0.05));
  padding: 3px 10px;
}

#togglersubmenu {
  height: 2.5rem;
  width: 2.5rem;
}

#togglersubmenu:hover {
  background: #80808021 !important;
}

.bg-grey {
  background: #eeeeee;
}

.h-13rem {
  height: 10rem;
}

.dash-header-info div {
  padding: 0 1rem;
  border-right: 1px solid;
  height: 50px;
  color: white;
  font-weight: 600;
  border-image: linear-gradient(#00000000, #FFFFFF, #00000000) 1 100%;
  min-width: 10rem;
}

.dash-header-info div p {
  margin-bottom: 0;
}

.mt-n6 {
  margin-top: -4rem;
}

.transform-270 {
  transform: rotate(270deg);
}

.transform-90 {
  transform: rotate(90deg);
}

.font-weight-600 {
  font-weight: 600 !important;
}

.filter {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.card-icon {
  height: 20px;
  width: 20px;
}

.h-17 {
  height: 13rem;
}

.search:focus {
  background-color: #eee !important;
}

.dashboard-content {
  min-height: 100vh;
  min-width: 71.74vw;
  margin-right: 24.625rem;
  transition: margin .25s ease-out;
}

.panel-content {
  display: block;
  position: fixed;
  top: 70px;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  padding: 0;
  background-color: #FAFAFA;
  border-right: 1px solid #FAFAFA;
  transition: margin .25s ease-out;
  right: 0;
}

.feed .post-item {
  border-bottom: 1px solid #E0E1E3;
  padding: 0rem var(--spacing-4, 0.25rem) 0.625rem var(--spacing-4, 0.25rem);
  padding-top: 0.5rem !important;
}

.feed span {
  width: 20px;
  float: left;
}

.feed p {
  margin-left: 25px;
  color: var(--black-100, #1C1C1C);
  margin-bottom: 2px;
}

.feed p.small {
  color: var(--black-40, rgba(28, 28, 28, 0.40));
}

.dash-tab-comp img {
  width: 30px;
  float: left;
  margin-top: 0.5rem;
}

.dash-tab-comp p {
  margin-left: 35px;
  margin-bottom: 2px;
}

.dash-tab-comp p.small {
  color: var(--black-40, rgba(28, 28, 28, 0.40));
}

.text-gray {
  color: var(--black-40, rgba(28, 28, 28, 0.40));
}

.text-black {
  color: #232323 !important;
}

.news-feed img {
  width: 7rem;
  float: right;
}

.news-feed p {
  color: var(--black-100, #1C1C1C);
  margin-bottom: 2px;
}

.news-feed p.small {
  color: var(--black-40, rgba(28, 28, 28, 0.40));
}

.sub-menu {
  margin-left: 4rem;
  background-color: #232323;
  border: 1px solid #232323;
  transition: all 0.3s linear;
}

.mob-sub-menu {
  margin-left: 0 !important;
}

.sub-menu.close {
  margin-left: -20rem !important;
  transition: all 0.3s linear;
}

.grid-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)); */
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.companies-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19%, 1fr));
  grid-gap: 1%;
}

.card-visibility {
  margin-top: -4rem;
}

@media (max-width: 767px) {
  .card-visibility {
    margin-top: 1rem;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    background-color: #fff;
  }

  .recent-app {
    height: auto !important;
    padding-bottom: 10px;
  }

  .companies-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}

@media (min-width: 768px) {
  .grid-container .item1 {
    grid-row: span 2;
    grid-column: span 2;
  }

  .grid-container .item4 {
    grid-column: span 2;
    /* grid-row: 2/3; */
  }

}

.recent-app-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  grid-gap: 12px;
  text-align: center;
}

.recent-app-grid .grid-app {
  overflow: hidden;
}

.cell-chip {
  background: #00800047;
  padding: 2px 5px;
  border-radius: 20px;
  color: green;
  font-size: small;
}

.cell-chip.odd-cell {
  color: red;
  background: #ff000029;
}

.cell-chip.odd-cell i {
  transform: rotate(90deg);
}

.card-section {
  border-bottom: 1px solid gainsboro;
  color: #232323;
}

.card-section .nav-link {
  cursor: pointer;
}

.card-section nav .nav-link.active {
  font-weight: 600;
  border-bottom: 2px solid;
  color: #232323;
}

.card-filter {
  background: #f6f6f6;
  border-bottom: 1px solid gainsboro;
}

.card-filter select {
  min-width: 6rem;
}

.load-more {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.bl-width-16rem {
  width: 16rem !important;
}

.bl-width-7rem {
  width: 7rem !important;
}

.bl-width-6rem {
  width: 6rem !important;
}

.bl-width-8rem {
  width: 8rem !important;
}

/* #fundtable .p-treetable-table {
  width: auto;
} */

.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}


/* .loader {
  width: 320px;
  height: 150px;
  display: block;
  position: relative;
  background: #FFF;
  box-sizing: border-box;
}
.loader::after {
  content: '';  
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  left: 30px;
  position: absolute;
  background-image: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%), linear-gradient(#DDD 56px, transparent 0), linear-gradient(#DDD 24px, transparent 0), linear-gradient(#DDD 18px, transparent 0), linear-gradient(#DDD 66px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
} */


.loader {
  width: 50%;
  height: 150px;
  display: block;
  position: relative;
  background: #FFF;
  box-sizing: border-box;
}

.loader::after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%), linear-gradient(#DDD 56px, transparent 0), linear-gradient(#DDD 24px, transparent 0), linear-gradient(#DDD 18px, transparent 0), linear-gradient(#DDD 66px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 100% 56px;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }

  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}

.loaderText {
  bottom: 0;
  position: absolute;
  z-index: 999;
  left: 15px;
  right: 15px;
  font-family: monospace;
  color: gray;
  text-align: center;
  font-size: smaller;
}

.loader2 {
  width: 100%;
  height: 10px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.579);
  overflow: hidden;
}

.loader2::after {
  content: '';
  box-sizing: border-box;
  width: 0;
  height: 5px;
  background: #858796;
  position: absolute;
  top: 0;
  left: 0;
  animation: animFw 50s linear infinite;
}

@keyframes animFw {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle-view::after {
  display: inline-block !important;
}

.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.fab-container .fab {
  position: relative;
  height: 70px;
  width: 70px;
  background-color: #E94359;
  border-radius: 50%;
  z-index: 2;
}

.fab-container .fab:hover {
  cursor: pointer;
  background-color: #e90321;
}

.fab-container .fab::before {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: inherit;
  border-radius: 0 0 10px 0;
  z-index: -1;
}

.fab-container .fab .fab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}


.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  /* height: 80px; */
}
.lds-ellipsis div {
  position: absolute;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: #47abe1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.bl-markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

.bl-markdown-content th, .bl-markdown-content td {
  border: 1px solid #ddd;
  padding: 8px;
}

.bl-markdown-content th {
  background-color: #f4f4f4;
  text-align: left;
}
