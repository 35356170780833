.editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    /* background-color: #f9f9f9; */
  }
  
  .editor-toolbar button {
    padding: 3px 6px;
    border: 1px solid #ccc;
    background: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size:smaller;
    border-radius: 2px;
    cursor: pointer;

  }
  
  .editor-toolbar button.is-active {
    background-color: #e0e0e0;
  }
  
  .editor-toolbar button:hover {
    background-color: #f0f0f0;
  }
  
  .editor-toolbar select {
    padding: 4px 8px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  

.tiptap {
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    :first-child {
      margin-top: 0;
    }
  
    /* Table-specific styling */
    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;
  
      td {
        border: 1px solid #e3e6f0;
        box-sizing: border-box;
        min-width: 1em;
        padding: 6px 8px;
        position: relative;
        vertical-align: top;
  
        > * {
          margin-bottom: 0;
        }
      }
      th {
        box-sizing: border-box;
        min-width: 1em;
        padding: 6px 8px;
        position: relative;
        vertical-align: top;
        font-size: 0.775rem;
        > * {
          margin-bottom: 0;
        }
      }
  
      th {
        border: solid #e3e6f0 !important;
        border-width: 1px 1px 2px 1px !important;
        font-size: 0.775rem !important;
      }
  
      .selectedCell:after {
        background: var(--gray-2);
        content: "";
        left: 0; right: 0; top: 0; bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }
  
      .column-resize-handle {
        background-color: var(--blue);
        bottom: -2px;
        pointer-events: none;
        position: absolute;
        right: -2px;
        top: 0;
        width: 4px;
      }
    }
  
    .tableWrapper {
      margin: 1.5rem 0;
      overflow-x: auto;
    }
  
    &.resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }